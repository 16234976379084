<template>
  <div class="invoice-view">
    <v-card flat v-if="isLoading">
      <v-card-text>
        <v-progress-linear height="10" :indeterminate="true">
        </v-progress-linear>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card flat>
        <PrintViewBar title="History" type="statement" :showEmailBtn="showEmailBtn"
          :postfix="`view?key=${currentBusiness.code}`"
          :text="`Invoice from ${currentBusiness.name} click the link to view`" :name="currentBusiness.name" />

        <v-container fluid>
          <v-card-text class="pb-0">
            <v-layout row wrap>
              <v-flex class="text-left text-capitalize">
                <div class="headline font-weight-bold">
                  {{ currentBusiness.name }}
                </div>
                <div class="font-weight-medium">
                  {{ currentBusiness.address }} {{ currentBusiness.city }},
                  <span class="text-uppercase">{{ currentBusiness.state }}
                  </span>
                  {{ currentBusiness.zip }}
                </div>
                <div class="font-weight-medium">
                  {{ currentBusiness.phoneNumber }}
                </div>
                <div class="font-weight-medium text-lowercase">
                  {{ currentBusiness.email }}
                </div>
                <div class="font-weight-medium text-lowercase">
                  {{ currentBusiness.website }}
                </div>

                <div class="mt-5 display-3 font-weight-black blue--text">
                  HISTORY
                </div>
                <div class="font-weight-regular" v-if="Product">
                  <!-- <div class="font-weight-medium">BILL TO</div> -->
                  <div>ID: {{ Product.localId }}</div>
                  <div>Name: {{ Product.name }}</div>
                  <div>Price: {{ Product.price | currency }}</div>
                  <div>Cost: {{ Product.avgCost | currency }}</div>
                  <!-- <div>{{ Customer.address }}</div>
                  <div v-if="Customer.city">
                    {{ Customer.city }},<span class="text-uppercase">{{ Customer.state }}
                    </span>
                    {{ Customer.zip }}
                  </div> -->
                </div>
              </v-flex>
              <v-spacer> </v-spacer>
              <v-flex shrink class="text-right">
                <div v-if="!$vuetify.breakpoint.xsOnly">
                  <img height="120" class="logo" :src="currentBusiness.imageUrl" alt="" />
                </div>
                <div class="font-weight-regular">
                  <v-layout column>
                    <v-flex class="mb-3">
                      <div class="text-right">
                        FROM : {{ this.start | moment("MM/DD/YYYY") }}
                      </div>
                      <div class="text-right">
                        TO : {{ this.end | moment("MM/DD/YYYY") }}
                      </div>
                    </v-flex>

                    <!-- <v-flex shrink v-if="Customer">
                      <div class="
                          headline
                          font-weight-medium
                          text-right
                          blue--text
                        ">
                        BALANCE
                      </div>
                      <div class="display-2 font-weight-light text-right">
                        {{ Customer.balance | currency }}
                      </div>
                      <br />
                    </v-flex> -->
                  </v-layout>
                </div>
              </v-flex>
            </v-layout>
          </v-card-text>
          <table class="center mt-5">
            <tbody class="report-container">
              <v-card-text class="pt-0">
                <v-data-table dense :headers="headers" :items-per-page="itemsPerPage" hide-default-footer
                  :items="Statement">
                  <template v-slot:item="{ item, index }">
                    <tr>
                      <td class="text-left"
                        :class="{ 'green--text': item.type === 'Purchase', 'red--text': item.type === 'Adjustment' }">
                        <span v-if="item.localId">
                          <a href="#" @click="view(item)">{{ item.localId }}</a>
                        </span>
                      </td>
                      <td class="text-left"
                        :class="{ 'green--text': item.type === 'Purchase', 'red--text': item.type === 'Adjustment' }">
                        <span v-if="item.date">{{
                          item.date | moment("MM/DD/YYYY")
                          }}</span>
                      </td>
                      <td :class="{ 'green--text': item.type === 'Purchase', 'red--text': item.type === 'Adjustment' }">
                        {{ item.name }}</td>

                      <td class="text-right">
                        <span class="black--text"
                          :class="{ 'green--text': item.type === 'Purchase', 'red--text': item.type === 'Adjustment' }">{{
                            item.type }}
                          <span v-if="item.localId"># {{ item.localId }}</span>
                        </span>
                        <!-- <span v-else-if="item.type === 'Purchase'" >Purchase # {{ item.localId }} </span>
                        <span v-else >Purchase # {{ item.type }} </span> -->

                      </td>
                      <td class="text-right"
                        :class="{ 'green--text': item.type === 'Purchase', 'red--text': item.type === 'Adjustment' }">
                        <span>{{ item.qty }}</span>
                        <!-- <a href="#" @click="view(item)">{{ item.qty }}</a></span> -->
                      </td>
                      <td class="text-right" :class="{
                        'green--text': item.remainingBalance === 0,
                      }">
                        {{ item.remainingBalance }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </tbody>
          </table>
          
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import PrintViewBar from "@/components/PrintViewBar";

import productService from "../../../Product/service";
import Hashids from "hashids";
import { mapGetters, mapActions } from "vuex";
const hashids = new Hashids();

export default {
  data() {
    return {
      date: this.$moment(),
      showEmailBtn: false,
      isLoading: true,
      Statement: [],
      Business: null,
      balance: 0,
      start: null,
      end: null,
      Product: null,
      headers: [
        {
          text: "ID",
          align: "left",
          value: "productDetail",
          sortable: false,
        },
        {
          text: "DATE",
          align: "left",
          value: "productDetail",
          sortable: false,
        },
        {
          text: "NAME",
          align: "center",
          value: "name",
          sortable: false,
        },
        {
          text: "TYPE",
          align: "center",
          value: "qty",
          sortable: false,
        },
        {
          text: "QUANTITY",
          align: "right",
          value: "qty",
          sortable: false,
        },
        {
          text: "BALANCE",
          align: "right",
          value: "total",
          sortable: false,
        },
      ],
      totalQty: 0,
    };
  },
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
    itemsPerPage() {
      return this.Statement.length;
    },

  },
  created() {
    this.loadData();
    if (!this.currentBusiness) this.initUser();
  },
  components: { PrintViewBar },
  methods: {
    ...mapActions("global", ["initUser", "initBusiness"]),
    remainingBalance() {
      let tempBalance = parseInt(this.balance)
      return this.Statement.map((row) => {
        // console.log('tempBalance type', row.type)
        if (row.type === "Invoice") {
          row.remainingBalance = (tempBalance -= parseInt(row.qty));
        }
        else if (row.type === "Purchase") {
          row.remainingBalance = (tempBalance += parseInt(row.qty));
        }
        else if (row.type === "Adjustment") {

          row.remainingBalance = (tempBalance += parseInt(row.qty));
          // if (row.adjustmentType === 'Increase') {
          //   return (tempBalance += parseInt(row.qty));
          // } else {
          //   return (tempBalance -= parseInt(row.qty));
          // }

        }
        // else {
        //   console.log('parseInt(row.qty)', parseInt(row.qty))
        //   return (tempBalance += parseInt(row.qty));
        // }
        // if (row.remainingBalance === 0) {
        //   console.log('row.remainingBalance', row.remainingBalance, row)
        // }
        // console.log('row.remainingBalance', row.remainingBalance)
      });
    },
    combineQuantities(objects) {
      const combined = {};

      objects.forEach(obj => {

        const id = obj.id; // Assuming each object has an 'id' key
        const qty = obj.qty; // Assuming each object has a 'quantity' key

        if (combined[id]) {
          combined[id].qty += qty; // Combine quantities
        } else {
          combined[id] = { ...obj }; // Create a new entry if ID doesn't exist
        }
      });

      // Convert combined object back into an array
      return Object.values(combined).map(item => {
        // console.log('obj', item)
        item.qty = item.qty // Set quantity to 1 for the combined entries

        return item;
      });
    },
    view(invoice) {
      const hashId = hashids.encode(invoice.id);
      let routeData = null;
      if (invoice.type === "Invoice") {
        routeData = this.$router.resolve({
          path: `/global/Invoice/${hashId}/view`,
        });
      } else {
        if (this.$route.meta.public) {
          routeData = this.$router.resolve({
            path: `/public/purchase/${hashId}/view`,
          });
        } else {
          routeData = this.$router.resolve({
            path: `/global/purchase/${hashId}/view`,
          });
        }
      }

      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    async renderData(response) {
      console.log('response', response)
      let Statement = null
      await Promise.all(
        response.data.statementInvoiceDetail.map((row) => {
          row.type = "Invoice";
          row.date = row.Invoice.date;
          row.localId = row.Invoice.localId;
          row.id = row.InvoiceId;
          row.name = (row.Invoice.Customer ? row.Invoice.Customer.name : 'Deleted >> N/A')
        })
      );

      await Promise.all(
        response.data.statementPurchaseDetail.map((row) => {
          // console.log('row', row)
          row.date = row.Purchase.date;
          row.localId = row.Purchase.localId;
          row.id = row.PurchaseId;
          row.type = "Purchase";
          row.name = (row.Purchase.Vendor ? row.Purchase.Vendor.name : 'Deleted >> N/A')
          // delete row.Purchase;
        })
      );

      await Promise.all(
        response.data.statementStockAdjustment
          .map((row) => {
            // console.log('row Adjustment', row)
            row.date = row.createdAt;
            row.qty = row.adjustmentQuantity
            // row.localId = row.Purchase.localId;
            // row.id = row.PurchaseId;
            row.type = "Adjustment";
            row.name = `${row.reason} By ${row.User?.name ?? 'N/A'}`;
            // row.name = `${row.reason} By ${row.User.name}`

            // delete row.Purchase;
          })
      );

      // this.Statement = response.data.statementInvoiceDetail;
      Statement = response.data.statementPurchaseDetail;
      Statement = Statement.concat(
        response.data.statementInvoiceDetail
      );
      Statement = Statement.concat(
        response.data.statementStockAdjustment
      );

      // this.Customer = response.data.customer;
      // this.Business = response.data.business;

      this.balance = response.data.previousPurchaseTotal + response.data.previousStockAdjustment - response.data.previousInvoiceTotal



      this.Statement = this.combineQuantities(Statement)

      this.Statement.unshift({
        date: this.start,
        type: "Previous Balance",
        qty: this.balance,
      });
      this.Statement.sort(function (a, b) {
        var dateA = new Date(a.date),
          dateB = new Date(b.date);
        return dateA - dateB;
      });

      this.$Progress.finish();
      // document.title = `Invoice # ${this.Invoice.localId}`;

      this.Product = response.data.product

      this.remainingBalance()
      this.isLoading = false;
      console.log('statement calling', this.Statement)
      // this.Statement.map(row => {
      //   if (row.type === 'Purchase') {
      //     console.log('row', row)
      //   }
      // })
    },
    loadData() {
      const id = this.$route.params.id
      this.start = this.$route.query.start;
      this.end = this.$route.query.end;
      return productService
        .getHistory(id, { start: this.start, end: this.end })
        .then((response) => {

          this.renderData(response);
        });
    },
  },
};
</script>

<style scoped>
@media print {
  container {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  .no-print {
    display: none;
  }

  .detailBox {
    overflow: visible;
    height: 100%;
  }

  * {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}

.paidImg {
  position: absolute;
  left: 40%;
  top: 10%;
}

.signature {
  margin-top: 100px;
}

.center {
  background-color: white;
  margin: auto;
  width: 100%;
}

/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>
