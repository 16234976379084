<template>
  <div class="invoice-view">
    <v-card flat v-if="isLoading">
      <v-card-text>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card flat>
        <ReportViewBar v-if="Product" :title="`Product List`" />

        <v-container fluid>
          <table class="center">
            <!-- <v-divider class="mx-3"></v-divider> -->
            <tbody class="report-container">
              <v-card-text class="pt-0">
                <v-data-table dense sort-by="name" :headers="headers" :itemsPerPage="Product.length" hide-default-footer
                  :items="Product">
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.localId }}</td>
                      <td>{{ item.name }}</td>
                      <td class="text-right">{{ item.price | currency }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </tbody>
          </table>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import ReportViewBar from "@/components/ReportViewBar";
import productService from "@/modules/Product/service";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      showEmailBtn: false,
      isLoading: true,
      Product: null,
      headers: [
      {
          text: "ID",
          align: "left",
          value: "localId",
          sortable: true,
        },
        {
          text: "NAME",
          align: "left",
          value: "name",
          sortable: true,
        },

        {
          text: "PRICE",
          align: "left",
          value: "price",
          sortable: true,
        },
      ],
      totalQty: 0,
    };
  },
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
  },
  created() {
    this.loadData();
    if (!this.currentBusiness) this.initUser();
  },
  components: { ReportViewBar },
  methods: {
    ...mapActions("global", ["initUser", "initBusiness"]),
    loadData() {
      // const id = hashids.decode(this.$route.params.id);

      return productService.getAll().then((response) => {
        this.$Progress.finish();
        this.Product = response.data;

        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped>
@media print {
  container {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  .no-print {
    display: none;
  }

  .detailBox {
    overflow: visible;
    height: 100%;
  }

  * {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}

.paidImg {
  position: absolute;
  left: 40%;
  top: 10%;
}

.signature {
  margin-top: 100px;
}

.center {
  background-color: white;
  margin: auto;
  width: 100%;
}

/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>
